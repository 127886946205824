<template>
  <div class="m-4 user-list">
    <nav class="level is-mobile mx-4">
      <div class="level-left">
        <div class="level-item">
          <b-button
            icon-left="chevron-left"
            rounded
            type="is-primary is-light"
            @click="prevMonth"
          ></b-button>
        </div>
        <div class="level-item">
          <span class="pl-2 is-size-3">{{ targetYearMonth }}</span>
        </div>
        <div class="level-item">
          <b-button
            icon-left="chevron-right"
            rounded
            type="is-primary is-light"
            @click="nextMonth"
            :disabled="nextYearMonth === undefined"
          ></b-button>
        </div>
      </div>
      <div class="level-right">
        <div class="field is-grouped">
          <p class="control" v-if="isAttendanceSheetAvailable">
            <b-tooltip
              label="全員分の勤務表をダウンロードします"
              type="is-light is-warning"
            >
              <b-button
                type="is-primary is-light"
                icon-left="download"
                @click="downloadAttendanceSheets"
                >当月勤務表</b-button
              >
            </b-tooltip>
          </p>
          <p class="control" v-if="isAdvanceSheetAvailable">
            <b-tooltip
              label="全員分の立替金申請をダウンロードします"
              type="is-light is-warning"
            >
              <b-button
                type="is-info is-light"
                icon-left="download"
                @click="downloadAdvanceSheets"
                >当月立替金申請</b-button
              >
            </b-tooltip>
          </p>
          <p class="control" v-if="isResumeAvailable">
            <b-tooltip
              label="選択された経歴書をダウンロードします"
              type="is-light is-warning"
            >
              <b-button
                :type="{
                  'is-light': true,
                  'is-success': checkedRows.length !== 0,
                }"
                icon-left="download"
                @click="downloadResumes"
                :disabled="checkedRows.length === 0"
                >経歴書</b-button
              >
            </b-tooltip>
          </p>
          <p class="control" v-if="isPaidLeaveManagementAvailable">
            <b-tooltip
              label="指定した条件に当てはまる社員の年次有給休暇を付与します"
              type="is-light is-warning"
            >
              <b-button
                type="is-light is-warning"
                icon-left="calendar-plus"
                @click="grantPaidAnnualLeave"
                >年休付与</b-button
              >
            </b-tooltip>
          </p>
          <p class="control">
            <b-button
              type="is-primary"
              icon-left="account-plus"
              @click="isFormOpen = true"
              >新規登録</b-button
            >
          </p>
        </div>
      </div>
    </nav>
    <div class="field is-grouped">
      <p class="control">
        <b-field>
          <b-switch v-model="isAttendanceSheetAvailable"> 勤務表 </b-switch>
        </b-field>
      </p>
      <p class="control">
        <b-field>
          <b-switch v-model="isAdvanceSheetAvailable" type="is-info">
            立替金
          </b-switch>
        </b-field>
      </p>
      <p class="control">
        <b-field>
          <b-switch v-model="isResumeAvailable" type="is-success">
            経歴書
          </b-switch>
        </b-field>
      </p>
      <p class="control">
        <b-field>
          <b-switch v-model="isPaidLeaveManagementAvailable" type="is-warning">
            年休
          </b-switch>
        </b-field>
      </p>
      <p class="control">
        <b-field>
          <b-switch v-model="isPassCycleManagementAvailable" type="is-light">
            定期代
          </b-switch>
        </b-field>
      </p>
      <p class="control">
        <b-field>
          <b-switch v-model="isAccountManagementAvailable" type="is-danger">
            アカウント
          </b-switch>
        </b-field>
      </p>
    </div>

    <b-table
      striped
      hoverable
      narrowed
      :data="users"
      checkable
      checkbox-position="left"
      :checked-rows.sync="checkedRows"
      sticky-checkbox
      scrollable
      default-sort="number"
      sort-icon="chevron-up"
      sticky-header
    >
      <b-table-column field="number" label="社員番号" centered sticky sortable>
        <template v-slot:header> 社員<br />番号 </template>
        <template v-slot="props">
          {{ props.row.number }}
        </template>
      </b-table-column>

      <b-table-column field="name" label="氏名" v-slot="props" sticky>
        {{ props.row.name }}
      </b-table-column>

      <b-table-column
        field="working_years"
        label="年次"
        v-slot="props"
        centered
      >
        {{ props.row.working_years }}
      </b-table-column>

      <b-table-column
        field="position_grade"
        label="職位グレード"
        v-slot="props"
        centered
      >
        {{ props.row.position }}{{ props.row.grade }}
      </b-table-column>

      <b-table-column
        v-if="isAccountManagementAvailable"
        field="role"
        label="権限"
        v-slot="props"
        centered
      >
        {{ displayRole(props.row.role) }}
      </b-table-column>

      <b-table-column
        field="attendance_sheet"
        label="勤務表締め"
        v-slot="props"
        centered
        :visible="isAttendanceSheetAvailable"
      >
        <closable-cell :closedAt="props.row.attendanceSheetClosedAt" />
      </b-table-column>

      <b-table-column
        field="advance_sheet"
        label="立替金締め"
        v-slot="props"
        centered
        :visible="isAdvanceSheetAvailable"
      >
        <closable-cell :closedAt="props.row.advanceSheetClosedAt" />
      </b-table-column>

      <b-table-column
        field="resume"
        label="経歴書更新"
        v-slot="props"
        centered
        :visible="isResumeAvailable"
      >
        {{ displayDate(props.row.cv_updated_at) }}
      </b-table-column>

      <b-table-column
        field="paid_leave"
        label="年休残"
        centered
        :visible="isPaidLeaveManagementAvailable"
      >
        <template v-slot:header> 年休<br />残 </template>
        <template v-slot="props">
          {{ props.row.paid_annual_leave_history.days_balance || 0 }}
        </template>
      </b-table-column>

      <b-table-column
        field="paid_leave"
        label="年休消化"
        centered
        :visible="isPaidLeaveManagementAvailable"
      >
        <template v-slot:header> 年休<br />消化 </template>
        <template v-slot="props">
          {{ -props.row.paid_annual_leave_history.days_consumed || 0 }}
        </template>
      </b-table-column>

      <b-table-column
        field="paid_leave"
        label="年休次回付与"
        centered
        :visible="isPaidLeaveManagementAvailable"
      >
        <template v-slot:header> 年休<br />次回付与 </template>
        <template v-slot="props">
          {{ props.row.paid_annual_leave_history.next_grant.days || 0 }}
        </template>
      </b-table-column>

      <b-table-column
        field="paid_leave"
        label="年休次回付与日付"
        centered
        :visible="isPaidLeaveManagementAvailable"
      >
        <template v-slot:header> 年休<br />次回付与日 </template>
        <template v-slot="props">
          {{ displayDate(props.row.paid_annual_leave_history.next_grant.date) }}
        </template>
      </b-table-column>

      <b-table-column
        field="prev_pass_cycle_transferred_on"
        label="前回定期振込"
        centered
        :visible="isPassCycleManagementAvailable"
      >
        <template v-slot:header> 前回定期<br />振込日 </template>
        <template v-slot="props">
          {{
            getPassCycleDate(props.row.pass_history, "prev", "transferred_on")
          }}
        </template>
      </b-table-column>

      <b-table-column
        field="prev_pass_cycle_period_begin_on"
        label="前回定期期間開始"
        centered
        :visible="isPassCycleManagementAvailable"
      >
        <template v-slot:header> 前回定期<br />期間開始 </template>
        <template v-slot="props">
          {{
            getPassCycleDate(props.row.pass_history, "prev", "period_begin_on")
          }}
        </template>
      </b-table-column>

      <b-table-column
        field="next_pass_cycle_transferred_on"
        label="次回定期代振込予定"
        centered
        :visible="isPassCycleManagementAvailable"
      >
        <template v-slot:header> 次回定期<br />振込予定日 </template>
        <template v-slot="props">
          {{
            getPassCycleDate(props.row.pass_history, "next", "transferred_on")
          }}
        </template>
      </b-table-column>

      <b-table-column
        field="prev_pass_cycle_period_begin_on"
        label="次回定期期間開始予定"
        centered
        :visible="isPassCycleManagementAvailable"
      >
        <template v-slot:header> 次回定期<br />期間開始予定 </template>
        <template v-slot="props">
          {{
            getPassCycleDate(props.row.pass_history, "next", "period_begin_on")
          }}
        </template>
      </b-table-column>

      <b-table-column
        field="deactivated_on"
        label="利用停止日"
        v-slot="props"
        centered
        :visible="isAccountManagementAvailable"
      >
        <span v-if="props.row.expired_at">{{
          displayDate(props.row.expired_at)
        }}</span>
      </b-table-column>

      <b-table-column field="operations" v-slot="props">
        <div class="b-tooltips">
          <b-tooltip
            label="勤務表閲覧"
            v-if="isAttendanceSheetAvailable"
            type="is-warning is-light"
          >
            <b-button
              icon-left="text-box-search"
              size="is-small"
              :type="{
                'is-primary': props.row.attendanceSheetClosedAt !== 'n/a',
                'is-light': true,
              }"
              :disabled="props.row.attendanceSheetClosedAt === 'n/a'"
              tag="router-link"
              :to="{
                name: 'adminAttendanceSheet',
                params: {
                  userId: props.row.id,
                  year: period.year(),
                  month: period.month() + 1,
                },
              }"
              >勤怠</b-button
            >
          </b-tooltip>

          <b-tooltip
            label="勤務表ダウンロード"
            v-if="isAttendanceSheetAvailable"
            type="is-warning is-light"
          >
            <b-button
              icon-left="download"
              :type="{
                'is-primary':
                  props.row.attendanceSheetClosedAt !== 'n/a' &&
                  props.row.attendanceSheetClosedAt !== 'open',
                'is-light': true,
              }"
              size="is-small"
              :disabled="
                props.row.attendanceSheetClosedAt === 'n/a' ||
                props.row.attendanceSheetClosedAt === 'open'
              "
              @click="downloadAttendanceSheetByUser(props.row.id)"
              >勤怠</b-button
            >
          </b-tooltip>

          <b-tooltip
            label="勤務表締め解除"
            v-if="isAttendanceSheetAvailable"
            type="is-warning is-light"
          >
            <b-button
              icon-left="lock-open-variant"
              :type="{
                'is-primary':
                  props.row.attendanceSheetClosedAt !== 'n/a' &&
                  props.row.attendanceSheetClosedAt !== 'open',
                'is-light': true,
              }"
              :disabled="
                props.row.attendanceSheetClosedAt === 'n/a' ||
                props.row.attendanceSheetClosedAt === 'open'
              "
              size="is-small"
              @click="cancelAttendanceSheet(props.row.id)"
              >勤怠</b-button
            >
          </b-tooltip>

          <b-tooltip
            label="立替金閲覧"
            type="is-warning is-light"
            v-if="isAdvanceSheetAvailable"
          >
            <b-button
              icon-left="text-box-search"
              :type="{
                'is-info': props.row.advanceSheetClosedAt !== 'n/a',
                'is-light': true,
              }"
              :disabled="props.row.advanceSheetClosedAt === 'n/a'"
              size="is-small"
              tag="router-link"
              :to="{
                name: 'adminAdvanceSheet',
                params: {
                  userId: props.row.id,
                  year: period.year(),
                  month: period.month() + 1,
                },
              }"
              >立替</b-button
            >
          </b-tooltip>

          <b-tooltip
            label="立替金締め解除"
            type="is-warning is-light"
            v-if="isAdvanceSheetAvailable"
          >
            <b-button
              icon-left="lock-open-variant"
              :type="{
                'is-info':
                  props.row.advanceSheetClosedAt !== 'n/a' &&
                  props.row.advanceSheetClosedAt !== 'open',
                'is-light': true,
              }"
              :disabled="
                props.row.advanceSheetClosedAt === 'n/a' ||
                props.row.advanceSheetClosedAt === 'open'
              "
              size="is-small"
              @click="cancelAdvanceSheet(props.row.id)"
              >立替</b-button
            >
          </b-tooltip>

          <b-tooltip
            label="年休取得入力"
            type="is-warning is-light"
            v-if="isPaidLeaveManagementAvailable"
          >
            <b-button
              icon-left="calendar-plus"
              type="is-warning is-light"
              size="is-small"
              @click="openPaidAnnualLeaveForm(props.row.id)"
              >年休</b-button
            >
          </b-tooltip>

          <b-tooltip
            label="定期代振込履歴入力"
            type="is-warning is-light"
            v-if="isPassCycleManagementAvailable"
          >
            <b-button
              icon-left="card-plus"
              type="is-light"
              size="is-small"
              @click="openPassCycleForm(props.row.id)"
              >定期</b-button
            >
          </b-tooltip>

          <b-tooltip
            label="ログイン停止"
            type="is-warning is-light"
            v-if="isAccountManagementAvailable"
          >
            <b-button
              type="is-danger is-light"
              size="is-small"
              icon-left="account-cancel"
              @click="deactivate(props.row.id)"
              :disabled="+props.row.id === +currentUserId"
              >解除</b-button
            >
          </b-tooltip>
        </div>
      </b-table-column>
    </b-table>
    <b-loading is-full-page v-model="loading"></b-loading>
    <b-modal
      v-model="isFormOpen"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      :can-cancel="['escape']"
    >
      <template #default="props">
        <user-form @close="props.close"></user-form>
      </template>
    </b-modal>

    <b-modal
      v-model="isPassCycleFormOpen"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      :can-cancel="['escape']"
    >
      <template #default="props">
        <pass-cycle-form
          @close="props.close"
          :userId="targetUserId"
          :userName="targetUserName"
          :next="passCycleNext"
        ></pass-cycle-form>
      </template>
    </b-modal>

    <b-modal
      v-model="isPaidAnnualLeaveFormOpen"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      :can-cancel="['escape']"
    >
      <template #default="props">
        <paid-annual-leave-form
          @close="props.close"
          :userId="targetUserId"
          :userName="targetUserName"
        ></paid-annual-leave-form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import dayjs from "dayjs";
import ClosableCell from "../components/ClosableCell";
import PaidAnnualLeaveForm from "../components/PaidAnnualLeaveForm";
import PassCycleForm from "../components/PassCycleForm";
import UserForm from "../components/UserForm";
import { ROLE } from "../constants";
import fetchMixin from "../fetch-mixin";
import storeMixin from "../store-mixin";

export default {
  name: "UserList",
  mixins: [storeMixin, fetchMixin],
  components: { UserForm, ClosableCell, PassCycleForm, PaidAnnualLeaveForm },
  data() {
    return {
      period: dayjs(),
      loading: false,
      isFormOpen: false,
      isPassCycleFormOpen: false,
      isPaidAnnualLeaveFormOpen: false,
      targetUserId: null,
      targetUserName: null,
      passCycleNext: null,
      checkedRows: [],
      isAttendanceSheetAvailable: true,
      isAdvanceSheetAvailable: true,
      isResumeAvailable: false,
      isPaidLeaveManagementAvailable: false,
      isPassCycleManagementAvailable: false,
      isAccountManagementAvailable: false,
    };
  },
  props: {},
  computed: {
    users() {
      return this.$store.state.admin.users.map((user) => {
        const profileIndex = this.$store.state.admin.profiles.findIndex(
          (v) => +v.id === user.profile_id
        );
        const profile = this.$store.state.admin.profiles[profileIndex];
        return {
          attendanceSheetClosedAt: this.getClosedAt(user.attendance_sheets),
          advanceSheetClosedAt: this.getClosedAt(user.advance_sheets),
          ...profile,
          ...user,
        };
      });
    },
    currentUserId() {
      return this.$store.getters["auth/currentUserId"];
    },
    targetYearMonth() {
      return this.period.format("YYYY年MM月");
    },
    prevYearMonth() {
      const date = this.period.subtract(1, "month");
      return { year: date.get("year"), month: date.get("month") + 1 };
    },
    nextYearMonth() {
      if (this.period.isSame(dayjs(), "month")) {
        return undefined;
      }
      const date = this.period.add(1, "month");
      return { year: date.get("year"), month: date.get("month") + 1 };
    },
  },
  methods: {
    displayDate(date) {
      return date === undefined || date === null
        ? ""
        : dayjs(date).format("YYYY/MM/DD");
    },
    displayRole(role) {
      return Object.values(ROLE).find((v) => v.value === role).label;
    },
    prevMonth() {
      this.period = this.period.subtract(1, "month");
    },
    nextMonth() {
      this.period = this.period.add(1, "month");
    },
    getSheet(data) {
      if (data === undefined) {
        return undefined;
      }
      const index = data.findIndex(
        (v) =>
          v.period_year.toString() +
            v.period_month.toString().padStart(2, "0") ===
          this.period.format("YYYYMM")
      );

      if (index === -1) {
        return undefined;
      }

      return data[index];
    },
    getClosedAt(data) {
      const sheet = this.getSheet(data);
      if (sheet === undefined) {
        return "n/a";
      }

      return sheet.closed_at === null
        ? "open"
        : this.displayDate(sheet.closed_at);
    },
    getPassCycleDate(data, key1, key2) {
      const date = data?.[key1]?.[key2];
      return data === null ? "" : this.displayDate(date);
    },
    openPassCycleForm(userId) {
      this.targetUserId = userId;
      const index = this.$store.state.admin.users.findIndex(
        (v) => +v.id === +userId
      );
      this.targetUserName = this.users[index].name;
      this.passCycleNext =
        this.$store.state.admin.users[index].pass_history?.next;
      this.isPassCycleFormOpen = true;
    },
    async reload() {
      try {
        this.fetch(async () => {
          await this.$store.dispatch("admin/allUsers");
        });
      } catch (e) {
        console.log(e);
      }
    },
    openPaidAnnualLeaveForm(userId) {
      this.targetUserId = userId;
      const index = this.$store.state.admin.users.findIndex(
        (v) => +v.id === +userId
      );
      this.targetUserName = this.users[index].name;
      this.isPaidAnnualLeaveFormOpen = true;
    },
    async deactivate(userId) {
      this.$buefy.dialog.prompt({
        type: "is-danger",
        hasIcon: true,
        trapFocus: true,
        message:
          "アカウント解除されたユーザーはログインすることができなくなります。<br>利用停止（予定）日を入力してください。",
        inputAttrs: {
          type: "date",
        },
        confirmText: "利用停止",
        cancelText: "キャンセル",
        onConfirm: async (value) => {
          try {
            await this.store("admin/deactivateUser", {
              userId,
              requestBody: {
                expired_at: dayjs(value).format("YYYY-MM-DDTHH:mm:ss"),
              },
            });
          } catch (e) {
            console.log(e);
          }
        },
      });
    },
    async cancelAttendanceSheet(userId) {
      const userIndex = this.$store.state.admin.users.findIndex(
        (v) => +v.id === userId
      );
      const user = this.$store.state.admin.users[userIndex];
      const profileIndex = this.$store.state.admin.profiles.findIndex(
        (v) => +v.id === user.profile_id
      );
      const name = this.$store.state.admin.profiles[profileIndex].name;
      this.$buefy.dialog.confirm({
        title: "注意",
        message: `${name}の${this.targetYearMonth}の勤務表の締め状態を解除します。`,
        type: "is-warning",
        hasIcon: true,
        onConfirm: async () => {
          try {
            const sheet = this.getSheet(user.attendance_sheets);
            const attendanceSheetId = sheet.id;
            await this.store("admin/cancelAttendanceSheetClosing", {
              userId,
              attendanceSheetId,
            });
          } catch (e) {
            console.log(e);
          }
        },
      });
    },
    async cancelAdvanceSheet(userId) {
      const userIndex = this.$store.state.admin.users.findIndex(
        (v) => +v.id === userId
      );
      const user = this.$store.state.admin.users[userIndex];
      const profileIndex = this.$store.state.admin.profiles.findIndex(
        (v) => +v.id === user.profile_id
      );
      const name = this.$store.state.admin.profiles[profileIndex].name;
      this.$buefy.dialog.confirm({
        title: "注意",
        message: `${name}の${this.targetYearMonth}の立替金申請の締め状態を解除します。`,
        type: "is-warning",
        hasIcon: true,
        onConfirm: async () => {
          try {
            const sheet = this.getSheet(user.advance_sheets);
            const advanceSheetId = sheet.id;
            await this.store("admin/cancelAdvanceSheetClosing", {
              userId,
              advanceSheetId,
            });
          } catch (e) {
            console.log(e);
          }
        },
      });
    },
    async downloadAttendanceSheets() {
      try {
        await this.fetch("admin/downloadAttendanceSheetXlsx", {
          year: this.period.year(),
          month: this.period.month() + 1,
        });
      } catch (e) {
        console.log(e);
      }
    },
    async downloadAttendanceSheetByUser(userId) {
      try {
        const userIndex = this.$store.state.admin.users.findIndex(
          (v) => +v.id === userId
        );
        const user = this.$store.state.admin.users[userIndex];
        const sheet = this.getSheet(user.attendance_sheets);
        await this.fetch("admin/downloadAttendanceSheetById", sheet.id);
      } catch (e) {
        console.log(e);
      }
    },
    async downloadAdvanceSheets() {
      try {
        await this.fetch("admin/downloadAdvanceSheetXlsx", {
          year: this.period.year(),
          month: this.period.month() + 1,
        });
      } catch (e) {
        console.log(e);
      }
    },
    async downloadResumes() {
      try {
        await this.fetch(
          "admin/downloadResume",
          this.checkedRows.map((item) => item.resume_id)
        );
      } catch (e) {
        console.log(e);
      }
    },
    async grantPaidAnnualLeave() {
      this.$buefy.dialog.prompt({
        type: "is-warning",
        hasIcon: true,
        trapFocus: true,
        message:
          "年次有給休暇の期限切れ消滅と新規付与を行います。次回付与予定日が入力された日付である社員が処理対象となります。日付を入力してください。",
        inputAttrs: {
          type: "date",
        },
        confirmText: "消滅及び付与",
        cancelText: "キャンセル",
        onConfirm: async (value) => {
          try {
            await this.store("admin/grantPaidAnnualLeave", {
              date: value,
            });
          } catch (e) {
            console.log(e);
          }
        },
      });
    },
  },
  async mounted() {
    await this.reload();
  },
};
</script>

<style lang="scss">
.b-tooltips {
  .b-tooltip:not(:last-child) {
    margin-right: 0.25em;
  }
}
.user-list .b-table .table-wrapper.has-sticky-header {
  height: 74vh;
}
</style>
