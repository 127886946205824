<template>
  <form>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">定期代振込履歴登録【{{ userName }}】</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body" id="pass-cycle-form-body">
        <b-field>
          <b-radio-button
            native-value="1"
            type="is-primary is-light"
            expanded
            v-model="type"
          >
            <b-icon icon="card-plus"></b-icon>
            <span>登録</span>
          </b-radio-button>

          <b-radio-button
            v-model="type"
            native-value="2"
            type="is-primary is-light"
            expanded
            :disabled="!hasNext"
          >
            <b-icon icon="credit-card-refresh"></b-icon>
            <span>継続</span>
          </b-radio-button>

          <b-radio-button
            v-model="type"
            native-value="3"
            type="is-primary is-light"
            expanded
            :disabled="!hasNext"
          >
            <b-icon icon="card-remove"></b-icon>
            <span>停止</span>
          </b-radio-button>
        </b-field>

        <template v-if="type !== '3'">
          <b-field
            label="振込日"
            :type="hasError('transferred_on')"
            :message="message('transferred_on')"
          >
            <b-datepicker
              icon="calendar"
              locale="ja"
              v-model="form.transferred_on"
              :disabled="type === '2'"
              :max-date="new Date()"
            ></b-datepicker>
          </b-field>
          <b-field
            label="期間開始日"
            :type="hasError('period_begin_on')"
            :message="message('period_begin_on')"
          >
            <b-datepicker
              icon="calendar-start"
              locale="ja"
              v-model="form.period_begin_on"
              :disabled="type === '2'"
            ></b-datepicker>
          </b-field>
          <b-field
            label="有効期間"
            :type="hasError('validity_period')"
            :message="message('validity_period')"
          >
            <b-input
              type="number"
              icon="credit-card-clock"
              min="1"
              v-model="form.validity_period"
              expanded
            ></b-input>
            <p class="control">
              <span class="button is-static">ヶ月</span>
            </p>
          </b-field>

          <b-field>
            <b-checkbox v-model="form.should_extend">継続要否</b-checkbox>
          </b-field>
        </template>
      </section>
      <footer class="modal-card-foot">
        <b-button label="キャンセル" @click="$emit('close')" expanded />
        <b-button
          label="保存"
          type="is-primary"
          expanded
          @click="save"
          :loading="storing"
        />
      </footer>
    </div>
  </form>
</template>

<script>
import { parseDateField } from "../fetch-mixin";
import storeMixin, { formatDateField } from "../store-mixin";

const initialForm = () => ({
  transferred_on: null,
  period_begin_on: null,
  validity_period: 6,
  should_extend: false,
});

export default {
  name: "PassCycleForm",
  mixins: [storeMixin],
  props: { userId: Number, userName: String, next: Object },
  data() {
    return {
      type: this.hasNext ? "2" : "1",
      form: initialForm(),
    };
  },
  computed: {
    hasNext() {
      return this.next !== undefined && this.next !== null;
    },
  },
  watch: {
    next(value) {
      this.form = {
        transferred_on: parseDateField(value?.transferred_on),
        period_begin_on: parseDateField(value?.period_begin_on),
        validity_period: value?.validity_period ?? 6,
        should_extend:
          value?.should_extend === undefined ? false : value?.should_extend,
      };
    },
  },
  methods: {
    async save() {
      try {
        if (this.type === "3") {
          await this.store("admin/updatePassCycle", {
            userId: this.userId,
            requestBody: { should_extend: false },
          });
        } else {
          await this.store("admin/addPassCycle", {
            userId: this.userId,
            requestBody: {
              transferred_on: formatDateField(this.form.transferred_on),
              period_begin_on: formatDateField(this.form.period_begin_on),
              validity_period: this.form.validity_period,
              should_extend: this.form.should_extend,
            },
          });
        }
        this.form = initialForm();
        this.$emit("close");
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style>
#pass-cycle-form-body {
  height: 560px;
}
</style>
