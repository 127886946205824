<template>
  <form>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">ユーザーアカウント新規登録</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <b-field
          label="氏名"
          :type="hasError('profile.name')"
          :message="message('profile.name')"
        >
          <b-input
            icon="account"
            autocomplete="off"
            v-model="form.profile.name"
            required
            maxlength="256"
          ></b-input>
        </b-field>
        <b-field
          label="ふりがな"
          :type="hasError('profile.furigana')"
          :message="{
            'ひらがなで入力してください。': hasError('profile.furigana'),
          }"
        >
          <b-input
            icon="syllabary-hiragana"
            v-model="form.profile.furigana"
            required
            maxlength="256"
            pattern="^[ぁ-ん \u3000]+$"
          ></b-input>
        </b-field>
        <b-field
          label="メールアドレス"
          :type="hasError('user.email')"
          :message="message('user.email')"
        >
          <b-input
            icon="email"
            autocomplete="off"
            v-model="form.user.email"
            required
            maxlength="245"
            expanded
          ></b-input>
          <p class="control">
            <span class="button is-static">@atago.cc</span>
          </p>
        </b-field>
        <b-field
          label="パスワード"
          :message="
            message('user.password') ||
            '英字大文字・小文字・数字・記号を使って8文字以上で入力してください'
          "
          :type="hasError('user.password')"
        >
          <b-input
            icon="form-textbox-password"
            type="password"
            autocomplete="off"
            v-model="form.user.password"
            password-reveal
            required
          ></b-input>
        </b-field>
        <b-field
          label="社員番号"
          :type="hasError('profile.number')"
          :message="message('profile.number')"
        >
          <b-input
            icon="identifier"
            v-model="form.profile.number"
            maxlength="4"
            pattern="^[0-9A-Za-z]+$"
            required
            title="半角英数字で入力してください"
          ></b-input>
        </b-field>

        <b-field
          label="入社年月日"
          :type="hasError('profile.joined_on')"
          :message="message('profile.joined_on')"
        >
          <b-datepicker
            icon="office-building"
            editable
            locale="ja-JP"
            v-model="form.profile.joined_on"
            required
          ></b-datepicker>
        </b-field>

        <b-field
          label="権限"
          :type="hasError('user.role')"
          :message="message('user.role')"
        >
          <b-select
            icon="account-question"
            v-model="form.user.role"
            required
            expanded
          >
            <option v-for="role in roles" :value="role.value" :key="role.value">
              {{ role.label }}
            </option>
          </b-select>
        </b-field>

        <b-field
          label="生年月日"
          :type="hasError('profile.date_of_birth')"
          :message="message('profile.date_of_birth')"
        >
          <b-datepicker
            icon="cake"
            editable
            locale="ja-JP"
            v-model="form.profile.date_of_birth"
            required
          ></b-datepicker>
        </b-field>
        <b-field
          label="性別"
          :type="hasError('profile.sex')"
          :message="message('profile.sex')"
        >
          <b-select icon="circle" v-model="form.profile.sex" expanded required>
            <option v-for="sex in sexes" :value="sex.value" :key="sex.value">
              {{ sex.label }}
            </option>
          </b-select>
        </b-field>
        <b-field
          label="標準始業時間"
          :type="hasError('time_setting.work_hour_begin_at')"
          :message="message('time_setting.work_hour_begin_at')"
        >
          <b-timepicker
            icon="clock"
            editable
            hour-format="24"
            locale="ja-JP"
            v-model="form.time_setting.work_hour_begin_at"
          >
          </b-timepicker>
        </b-field>
        <b-field
          label="標準終業時間"
          :type="hasError('time_setting.work_hour_end_at')"
          :message="message('time_setting.work_hour_end_at')"
        >
          <b-timepicker
            icon="clock"
            editable
            hour-format="24"
            locale="ja-JP"
            v-model="form.time_setting.work_hour_end_at"
          >
          </b-timepicker>
        </b-field>
        <b-field
          label="昼休憩開始時間"
          :type="hasError('time_setting.break_time_begin_at')"
          :message="message('time_setting.break_time_begin_at')"
        >
          <b-timepicker
            icon="clock"
            editable
            hour-format="24"
            locale="ja-JP"
            v-model="form.time_setting.break_time_begin_at"
          >
          </b-timepicker>
        </b-field>
        <b-field
          label="昼休憩終了時間"
          :type="hasError('time_setting.break_time_end_at')"
          :message="message('time_setting.break_time_end_at')"
        >
          <b-timepicker
            icon="clock"
            editable
            hour-format="24"
            locale="ja-JP"
            v-model="form.time_setting.break_time_end_at"
          >
          </b-timepicker>
        </b-field>
        <b-field
          label="SlackメンバーID"
          :type="hasError('profile.slack_user_name')"
          :message="message('profile.slack_user_name')"
        >
          <b-input
            icon="slack"
            v-model="form.profile.slack_user_name"
            maxlength="256"
          ></b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <b-button label="キャンセル" @click="$emit('close')" expanded />
        <b-button
          label="保存"
          type="is-primary"
          expanded
          @click="addUser"
          :loading="storing"
        />
      </footer>
    </div>
  </form>
</template>

<script>
import dayjs from "dayjs";

import { ROLE, SEX } from "../constants";
import storeMixin, {
  formatDateField,
  formatTimeField,
  clone,
} from "../store-mixin";

const initialForm = () => ({
  user: {
    email: null,
    password: null,
    role: ROLE.MEMBER.value,
  },
  profile: {
    name: null,
    furigana: null,
    date_of_birth: null,
    sex: null,
    marital_status: null,
    address: "",
    nearest_line: "",
    nearest_station: "",
    education: "",
    number: null,
    joined_on: null,
    first_paid_annual_leave_granted_on: null,
    slack_user_name: null,
  },
  time_setting: {
    work_hour_begin_at: dayjs({ hour: 9, minute: 0 }).toDate(),
    work_hour_end_at: dayjs({ hour: 18, minute: 0 }).toDate(),
    break_time_begin_at: dayjs({ hour: 12, minute: 0 }).toDate(),
    break_time_end_at: dayjs({ hour: 13, minute: 0 }).toDate(),
  },
});

export default {
  name: "UserForm",
  mixins: [storeMixin],
  data() {
    return {
      form: initialForm(),
    };
  },
  computed: {
    roles() {
      return Object.values(ROLE);
    },
    sexes() {
      return Object.values(SEX);
    },
  },
  methods: {
    async addUser() {
      const form = clone(this.form);
      form.user.email = form.user.email + "@atago.cc";
      form.profile.joined_on = formatDateField(form.profile.joined_on);
      form.profile.first_paid_annual_leave_granted_on =
        getFirstPaidAnnualLeaveGrantDate(form.profile.joined_on);
      form.profile.date_of_birth = formatDateField(form.profile.date_of_birth);
      form.profile.marital_status = 1;
      form.time_setting.work_hour_begin_at = formatTimeField(
        form.time_setting.work_hour_begin_at
      );
      form.time_setting.work_hour_end_at = formatTimeField(
        form.time_setting.work_hour_end_at
      );
      form.time_setting.break_time_begin_at = formatTimeField(
        form.time_setting.break_time_begin_at
      );
      form.time_setting.break_time_end_at = formatTimeField(
        form.time_setting.break_time_end_at
      );
      try {
        await this.store("admin/addUser", form);
        this.form = { ...this.form, ...initialForm() };
      } catch (e) {
        console.log(e);
      }
    },
  },
};

function getFirstPaidAnnualLeaveGrantDate(joinedOn) {
  const date = dayjs(joinedOn);
  const apr_1 = dayjs({ year: date.get("year"), month: 3, date: 1 }); // 4/1
  const oct_1 = dayjs({ year: date.get("year"), month: 9, date: 1 }); // 10/1

  if (date.isBefore(apr_1, "date")) {
    return apr_1.format("YYYY-MM-DD");
  } else if (date.isBefore(oct_1, "date")) {
    return oct_1.format("YYYY-MM-DD");
  } else {
    return apr_1.add(1, "year").format("YYYY-MM-DD");
  }
}
</script>
