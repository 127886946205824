<template>
  <div>
    <span v-if="closedAt === 'n/a'" class="has-text-danger has-text-weight-bold"
      >未記入</span
    >
    <span
      v-else-if="closedAt === 'open'"
      class="has-text-success has-text-weight-bold"
      >記入中</span
    >
    <span v-else>{{ closedAt }}</span>
  </div>
</template>

<script>
export default {
  name: "ClosableCell",
  props: {
    closedAt: {
      type: String,
    },
  },
};
</script>
