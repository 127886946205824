<template>
  <form>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">年次有給休暇取得登録【{{ userName }}】</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <b-field
          grouped
          v-for="(item, index) in form"
          :key="'pal-' + index"
          class="pl-4"
        >
          <b-field label="取得日">
            <b-input
              type="date"
              icon="calendar-today"
              v-model="item.changed_on"
              :disabled="item.$delete"
            ></b-input>
          </b-field>

          <b-field>
            <b-radio-button
              v-model="item.days"
              native-value="-1"
              type="is-success is-light is-outlined"
            >
              <b-icon icon="moon-full"></b-icon>
              <span>全休</span>
            </b-radio-button>

            <b-radio-button
              v-model="item.days"
              native-value="-0.5"
              type="is-warning is-light is-outlined"
            >
              <b-icon icon="circle-half-full"></b-icon>
              <span>半休</span>
            </b-radio-button>
          </b-field>

          <b-button
            :type="{
              'is-light': true,
              'is-danger': !item.$delete,
              'is-primary': item.$delete,
            }"
            :icon-left="item.$delete ? 'undo' : 'minus'"
            outlined
            @click="remove(index)"
          >
          </b-button>
        </b-field>
        <b-field class="mt-3">
          <b-button
            type="is-primary is-light"
            icon-left="calendar-plus"
            outlined
            @click="add"
            >追加
          </b-button>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <b-button label="キャンセル" @click="$emit('close')" expanded />
        <b-button
          label="保存"
          type="is-primary"
          expanded
          @click="save"
          :loading="storing"
        />
      </footer>
    </div>
  </form>
</template>

<script>
import storeMixin, { formatDateField } from "../store-mixin";
import { PAID_ANNUAL_LEAVE_CHANGE } from "../constants";

const initialForm = () => [
  {
    changed_on: null,
    days: "-1",
  },
];

export default {
  name: "PaidAnnualLeave",
  mixins: [storeMixin],
  props: { userId: Number, userName: String },
  data() {
    return {
      form: initialForm(),
    };
  },
  methods: {
    add() {
      this.form.push({
        changed_on: null,
        days: "-1",
      });
    },
    remove(index) {
      this.form.splice(index, 1);
    },
    async save() {
      const promises = this.form
        .filter((v) => v.changed_on !== null)
        .map((v) =>
          this.store("admin/changePaidAnnualLeaveStatus", {
            userId: this.userId,
            requestBody: {
              changed_on: formatDateField(v.changed_on),
              change: PAID_ANNUAL_LEAVE_CHANGE.TAKE.value,
              days: +v.days,
            },
          })
        );
      try {
        await Promise.all(promises);
        this.form = initialForm();
        this.$emit("close");
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style></style>
